<template>
  <div class="registration"> 
    <b-overlay :show="isLoading">
      <b-row>
        <b-col offset-md="2" md="8">
          <registration-form
            :config="formConfig"
            v-model="registrationData"
            @submit="doSubmit"
          />
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import { translateSetting } from "@/utils/translated-setting.util";
import RegistrationForm from "@/components/BaseForm.vue";

export default {
  components: { RegistrationForm },
  data() {
    return {
      isLoading: false,
      registrationData: {},
    };
  },
  computed: {
    formConfig() {
      return this.$store.getters["Registration/form"];
    },
  },
  methods: {
    async doSubmit() {
      this.isLoading = true;
      await this.$store.dispatch("Auth/addRegistration", this.registrationData);
      this.isLoading = false;
      this.$router.push({ name: "dashboard" });
    },
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("Registration/fetchAll");
    this.isLoading = false;
  },
};
</script>
<style lang="scss">
.registration {
  
  flex-grow: 1;
}
</style>
